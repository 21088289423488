/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */

// import * as fs from 'fs'
import { promises as fs } from 'fs'
import Head from 'next/head'
// import type { NextPage } from 'next'
import type { NextPage, GetServerSideProps } from 'next'
import dynamic from 'next/dynamic'
import getConfig from 'next/config'
const { serverRuntimeConfig } = getConfig()
import React from 'react'
import { createPortal } from 'react-dom'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import loadable from '@loadable/component'
const { loadComponent, fetchFlexCSPNonce } = await import(
  /* webpackMode: "lazy-once" */
  /* eager: true */
  /* webpackPreload: true */
  '@flexiness/domain-utils'
)
import { observer } from 'mobx-react-lite'
import { getStores } from 'flex-gateway-ssr/stores'
// import { fileExists, isServer, useHasMounted } from 'flex-gateway-ssr/utils'
import { fileExists, isServer } from 'flex-gateway-ssr/utils'

import homeStyles from 'flex-gateway-ssr/styles/modules/pages/home.module.css'

import { FlexComponents } from 'flex-gateway-ssr/flex-components'
const { Button, VariantState, ButtonMarkup, Icon, IconName, IconPosition, IconSize, styles } = FlexComponents

import { PageAppProps, PageStaticData, LogoProps } from '../../../additional'

// import { loadTranslations } from 'ni18n'
// import { ni18nConfig } from '@flexiness/languages/dist/ni18n.config'
// import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
// const nextI18NextConfig = await import('../../../next-i18next.config.mjs')
// import type I18next from 'i18next'
// let i18n: FlexI18next

const Logo = dynamic(() => import('flex-gateway-ssr/components/logo'))
const ContentNextV12 = dynamic(() => import('flex-gateway-ssr/components/home-content/nextv12'))
const ContentNextV13 = dynamic(() => import('flex-gateway-ssr/components/home-content/nextv13'))
let MFSlidesAbout: React.ComponentType<LogoProps> = Logo

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME'

// import { FlexComponents } from 'flex-gateway-ssr/flex-components'
// const { AlertState, Box, BoxContent, Switch } = FlexComponents

// const {
//   AlertState, Box, BoxContent, Switch
// } = await import('flex_design_system_react_ts_modfed/Styled')

const stores = getStores()

const host = `${process.env.FLEX_MF_HOMEPAGE_ABOUT_SLIDES_HOST}/web`
const mf = `${process.env.FLEX_MF_HOMEPAGE_ABOUT_SLIDES_NAME}`
const base = `home`
let werewolfCount: number = isMobile ? 1 : 0
let slideDOMContentLoaded: boolean = false

const _nonce = isServer
  ? JSON.parse(`${await fs.readFile(`${serverRuntimeConfig.PROJECT_ROOT}/nonce.json`)}`)['nonce']
  : await fetchFlexCSPNonce(`${process.env.FLEX_GATEWAY_HOST}`)

const loadFlex = async (props: PageAppProps) => {
  // ///////////////// LOAD MICROFRONT-END ////////////////////////
  MFSlidesAbout = loadable(async () => loadComponent({
    path: `${mf}/App`,
    remoteUrl: `${host}`,
    scope: 'default',
    // nonce: await fetchFlexCSPNonce(`${process.env.FLEX_GATEWAY_HOST}`),
    nonce: `${_nonce}`,
    apiUrl: `${process.env.FLEX_GATEWAY_HOST}`
  }))
  // //////////////////////////////////////////////////////////////

  import('@flexiness/domain-lib-mobx-react-router').then((factory) => {
    const { getMFStore } = factory
    const MFStore = getMFStore()
    MFStore.setId(`${props.mf}`)
    MFStore.setBasename(`/${base}`)
  })
}

const setSlideDOMContentLoaded = (bool: boolean) => {
  slideDOMContentLoaded = bool
  if (process.env.DEBUG === 'true') console.log(`NextJS : Slide DOMContentLoaded : ${slideDOMContentLoaded}`)
}

// const SlidesAbout = React.memo(function slidesAbout(props) {
interface SlidesAboutProps {
  slug?: string
  // setter: (num: number) => void
}

const SlidesAbout: React.FC<SlidesAboutProps> = React.memo(function slidesAbout({
  slug,
  // setter
}) {
  const [slidesOpen, setSlidesOpen] = React.useState(isMobile ? false : true)
  const [domReady, setDomReady] = React.useState(false)

  const setSlidesOpenTimer = () => {
    slideDOMContentLoaded
      ? setTimeout(() => {
          setSlidesOpen(true)
        }, 500)
      : setSlidesOpen(false)
  }

  const scrollSlide = (el:HTMLDivElement) => {
    if (!el) return
    // setTimeout(function() {
    //   el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    // }, 200)
    el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  }

  const appContextOverlyaMode = (bool: boolean) => {
    const {
      appContext,
      setAppContext
    } = stores.UIStore
    setAppContext(
      {
        ...appContext,
        overlayMode: bool
      }
    )
  }

  const handleCloseSlide = () => {
    const {
      appContext
    } = stores.UIStore
    if (slidesOpen) setSlideDOMContentLoaded(false)
    // setSlidesOpen(!slidesOpen)
    setSlidesOpenTimer()
    slidesOpen
      ? scrollSlide(document.querySelector('.flex-gateway-layout > .navigation > .flex-gateway-nav-menu')!)
      : scrollSlide(document.querySelector('.flex-gateway-layout > .component')!)
    appContextOverlyaMode(!appContext.overlayMode)
    // setter(werewolfCount + 1)
    werewolfCount = werewolfCount + 1
  }

  const {
    flexI18next
  } = stores.UIStore

  const {
    t
  } = flexI18next

  React.useEffect(() => {
    setDomReady(true)
    if (isMobile) return
    scrollSlide(document.querySelector('.flex-gateway-layout > .component')!)
    appContextOverlyaMode(true)
    // setter(werewolfCount)
  }, [])

  return (
    <div style = {{
        // position: 'relative'
      }}
    >
      <div
        className={classNames(
          slidesOpen ? homeStyles.slidesMenuOpen : homeStyles.slidesMenuClosed,
        )}
      >
        {slidesOpen ? (
          <button onClick={handleCloseSlide}
            className={classNames(
              homeStyles.slidesTogglerClose,
              homeStyles.strokeGreyInvert
            )}>
              <span />
              <span />
          </button>
        ) :
          isMobile
            ? ( 
              domReady
                ? createPortal(
                  <div className='w-full order-6 flex items-center justify-stretch flex-gateway-nav-menu-item'>
                    <Button
                      variant={VariantState.PRIMARY}
                      markup={ButtonMarkup.A}
                      className={classNames(
                        styles.isLowercase,
                        styles.isFullwidth,
                        'mb-2'
                      )} onClick={handleCloseSlide}>
                      <Icon content={t('gateway.home.show_slides')} size={IconSize.MEDIUM} position={IconPosition.LEFT} name={IconName.UI_EYE} />
                    </Button>
                  </div>,
                  document.querySelector(`#__next .flex-gateway-layout.aside.${process.env.FLEX_MF_HOMEPAGE_ABOUT_SLIDES_NAME} .navigation .flex-gateway-nav-menu`)!
                ) : null
           ) : (
              // <button onClick={handleCloseSlide} className={homeStyles.showSlideBtn}>
              //   <span className={homeStyles.showSlideBtnTxt}>Show me more{' '}</span>👁️‍🗨️
              // </button>

              <Button
                variant={VariantState.PRIMARY}
                markup={ButtonMarkup.A}
                className={classNames(
                  styles.isLowercase,
                )} onClick={handleCloseSlide}>
                <Icon content={t('gateway.home.show_slides')} size={IconSize.MEDIUM} position={IconPosition.LEFT} name={IconName.UI_EYE} />
              </Button>

              // <span onClick={handleCloseSlide}>
              //   <Icon content='Show me more' size={IconSize.MEDIUM} position={IconPosition.LEFT} name={IconName.UI_EYE} />
              // </span>
            )
        }
      </div>
      <div
        style = {{
          top: '0',
          left: '0',
          // width: 'calc(100% - var(--flex-aside-maxwidth))',
          width: '100%',
          height: slidesOpen ? '100vh' : '0',
          overflow: 'hidden',
          background: 'rgba(0,0,0,0.5)',
          backdropFilter: 'blur(3px)',
          zIndex: 2
        }}
        className={classNames(
          homeStyles.fadeTransitionSlides,
          slidesOpen ? 'fixed mf:absolute' : 'invisible',
        )}
      >
        {/* <MFSlidesAbout {...props} /> */}
        {/* 
        {slidesOpen &&
          <MFSlidesAbout slug={slug} />
        }
        */}
        <MFSlidesAbout slug={slug} />
      </div>
    </div>
  )
})

const Home: NextPage<PageAppProps> = observer((
  props
) => {
  const {
    appContext,
    setNavigationState,
    setStatus,
    navigationState,
    flexI18next
  } = stores.UIStore
  const [slideVisibility, setSlideVisibility] = React.useState(false)

  // const hasMounted = useHasMounted()
  // if (!hasMounted) {
  //   return null
  // } else {
  //   setTimeout(function() {
  //     // setNavigationState('aside')
  //     // setStatus(null)
  //   }, 1000)
  //   if (!isServer) loadFlex()
  // }

  if (!isServer) loadFlex(props)

  React.useEffect(() => {
    setTimeout(function() {
      setNavigationState('aside')
      setStatus(null)
    }, 1000)
  }, [])

  React.useEffect(() => {
    const postMessageWindowDimensionsToContentFrame = () => {
      let frame = document.getElementById(`${`${process.env.FLEX_MF_HOMEPAGE_ABOUT_SLIDES_PROXY_PATHNAME}`.replace(/\//, '_')}`) as HTMLIFrameElement
      if (isIFrame(frame) && frame.contentWindow) {
        frame.contentWindow.postMessage({ 
          resize: { 
            width: window.innerWidth,
            height: window.innerHeight
          },
          device: isMobile ? 'mobile' : 'desktop'
        }, `${process.env.FLEX_MF_HOMEPAGE_ABOUT_SLIDES_HOST}`)
      }
    }
    const onMessageReceivedFromContentFrame = (e: MessageEvent<any>) => {
      const { data, origin } = e
      if (origin === `${process.env.FLEX_MF_HOMEPAGE_ABOUT_SLIDES_HOST}` && data === 'Slides_ContentFrame_DOMContentLoaded') {
        setSlideDOMContentLoaded(true)
        postMessageWindowDimensionsToContentFrame()
      }
    }
    window.addEventListener('resize', postMessageWindowDimensionsToContentFrame)
    window.addEventListener('DOMContentLoaded', postMessageWindowDimensionsToContentFrame)
    window.addEventListener('message', e => onMessageReceivedFromContentFrame(e), false)
    postMessageWindowDimensionsToContentFrame()
    return () => {
      window.removeEventListener('resize', postMessageWindowDimensionsToContentFrame)
      window.removeEventListener('DOMContentLoaded', postMessageWindowDimensionsToContentFrame)
      window.removeEventListener('message', e => onMessageReceivedFromContentFrame(e), false)
    }
  }, [])

  React.useEffect(() => {
    const timerVisibilty = setTimeout(() => {
      setSlideVisibility(true)
    }, 2000)
    return () => {
      clearTimeout(timerVisibilty)
    }
  }, [navigationState !== 'fullpage'])

  const {
    language
  } = flexI18next

  // const getWereWolfCount = (count: number) => werewolfCount = count

  // const { _nonce } = props

  return (
    <>
      <Head>
        {/*
        <link nonce={_nonce} rel='stylesheet' href={`${process.env.FLEX_CONTENT_HOST}/css/gateway/home.${process.env.FLEX_BUILD_ID}.css`} />
        */}
        <title>Create Next App</title>
        <meta name='description' content='Generated by create next app' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <div className={classNames(
        'flex-view-component',
        isMobile && homeStyles.isFlexMobile
      )}>
        <div style={{
          zIndex: 1,
          ...(appContext.cssMode === 'Tailwind' &&
            { 
              // background: 'var(--flex-gateway-highlight, #f9423a)'
              background: '#000',
              color: '#fff'
            }
          )
        }}>
          {appContext.cssMode === 'CSS Modules'
            ? (
              <ContentNextV12 slideVisibility={slideVisibility} werewolf={Boolean(werewolfCount === 0 ? 1 : !(werewolfCount % 2))} />
            ) : (
              <ContentNextV13 />
            )
          }
        </div>
      </div>
      {slideVisibility && 
        <SlidesAbout {...{
          slug: `/locales/${language}/about.html`,
          // setter: getWereWolfCount
        }} />
      }
    </>
  )
})

// export async function getStaticProps() {
//   const pageName = base
//   const demoFile = await fileExists(`${pageName}.js`, '/src/components/navigation/demo-list')
//     ? pageName : 'default'

//   // const stores = getStores()
//   // const { setDemoPage } = stores.UIStore
//   // setDemoPage(demoFile)

//   const pageStaticData: PageStaticData = {
//     pageName,
//     demoFile,
//     mf
//   }
//   return {
//     props: {
//       ...pageStaticData
//     },
//     // revalidate: 1
//   }
// }

// // ///////////////////////////////////////////////////////////////////
// //                       getServerSideProps
// // ///////////////////////////////////////////////////////////////////
// // Will generate the page on each request. Cannot be used with getStaticProps or getStaticPaths
// // ///////////////////////////////////////////////////////////////////

export const getServerSideProps: GetServerSideProps = async (
  context
) => {
  // console.log('Page Home | getServerSideProps | context : ', context)
  // const {
  //   params, // present for pages that use a dynamic route,
  //   req,
  //   res,
  //   query,
  //   preview,
  //   previewData,
  //   resolvedUrl,
  //   locales,
  //   locale,
  //   defaultLocale
  // } = context

  const pageName = base
  const demoFile = await fileExists(`${pageName}.js`, '/src/components/navigation/demo-list')
    ? pageName : 'default'

  const pageStaticData: PageStaticData = {
    pageName,
    demoFile,
    mf
  }
  return {
    props: {
      // ...(await loadTranslations(ni18nConfig, `${locale ?? defaultLocale}`, [
      //   'translation'
      // ])),
      // ...(await serverSideTranslations(
      //   `${locale ?? defaultLocale}`,
      //   [ 'translation' ],
      //   nextI18NextConfig,
      //   ['en', 'fr']
      // )),
      ...pageStaticData
    }
  }
}

export default Home
